// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$joobzz-frontend-primary: mat.define-palette(mat.$indigo-palette);
$joobzz-frontend-accent: mat.define-palette(
  mat.$pink-palette,
  A200,
  A100,
  A400
);

// The warn palette is optional (defaults to red).
$joobzz-frontend-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$joobzz-frontend-theme: mat.define-light-theme(
  (
    color: (
      primary: $joobzz-frontend-primary,
      accent: $joobzz-frontend-accent,
      warn: $joobzz-frontend-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($joobzz-frontend-theme);

/* You can add global styles to this file, and also import other style files */
/* poppins-300 - latin_latin-ext */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  src: url('./assets/font-poppins/poppins-v20-latin_latin-ext-300.woff2')
    format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* poppins-regular - latin_latin-ext */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/font-poppins/poppins-v20-latin_latin-ext-regular.woff2')
    format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* poppins-500 - latin_latin-ext */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  src: url('./assets/font-poppins/poppins-v20-latin_latin-ext-500.woff2')
    format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* poppins-600 - latin_latin-ext */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  src: url('./assets/font-poppins/poppins-v20-latin_latin-ext-600.woff2')
    format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* poppins-700 - latin_latin-ext */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  src: url('./assets/font-poppins/poppins-v20-latin_latin-ext-700.woff2')
    format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --color-gray: #7e8299;
    --color-dark: #181c32;
    --color-yellow: #ffc300;
    --color-red: #ff5733;
    --color-purple: #9b59b6;
    --color-blue: #005aaa;
    --color-dark-blue: #011c34;
    --color-gray-light: #b5b5c3;
    --color-indigo: #2c3f5b;
    --color-gray-payne: #5e6278;
    --color-oxford-blue: #13263c;
    --color-gray-cool: #a1a5b7;
    --color-uranian-blue: #bee8ff;
    --color-ghost-white: #e4e6ef;
    --color-piction-blue: #5cb2e3;
    --color-anti-flash-white: #f5f8fa;
    --color-celestial-blue: #00a3ff;
    --color-alice-blue: #f2faff;
    --color-emerald: #50cd89;
    --color-charcoal: #3f4254;
    --color-lavender: #d9dbe4;
  }
}

.lang-select {
  position: relative !important;
  overflow: visible !important;
  isolation: isolate !important;
  z-index: 1 !important;
  background-color: white;
  border-radius: 4px !important ;
  filter: drop-shadow(0 4px 50px rgba(0, 0, 0, 0.15));
  &::after {
    content: '';
    position: absolute;
    top: -10px;
    left: 50%;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 5px 10px 5px;
    border-color: transparent transparent white transparent;
    transform: translateX(-50%);
    z-index: -1;
  }

  .mdc-list-item__primary-text {
    display: flex;
    align-items: center;
    img {
      margin-right: 5px;
    }
  }
}

.signup-btn {
  background-color: rgba(255, 255, 255, 0);
  &:focus,
  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
    outline: none;
  }
}

.btn-blue {
  background-color: rgba($color: #005aaa, $alpha: 1);
  &:focus,
  &:hover {
    background-color: rgba($color: #005aaa, $alpha: 0.8);
    outline: none;
  }
}

.btn-red {
  background-color: rgba($color: #ff5733, $alpha: 1);
  &:focus,
  &:hover {
    background-color: rgba($color: #ff5733, $alpha: 0.8);
    outline: none;
  }
}

.btn-green {
  background-color: rgba($color: #50cd89, $alpha: 1);
  &:focus,
  &:hover {
    background-color: rgba($color: #50cd89, $alpha: 0.8);
    outline: none;
  }
}

.btn-gray {
  background-color: #f4f4f4;
  &:focus,
  &:hover {
    background-color: #f9f9f9;
    outline: none;
  }
}

.btn-clear {
  color: var(--color-blue);
  &:focus,
  &:hover {
    color: var(--color-piction-blue);
    outline: none;
  }
}

.btn-disabled {
  &:disabled {
    opacity: 0.5;
  }
}

.btn-signup {
  background-size: 200%;
  background-position: left;
  background-image: linear-gradient(
    to right,
    var(--color-purple),
    var(--color-blue),
    var(--color-purple)
  );
  transition: background-position ease-in-out 200ms;
  &:hover {
    background-position: right;
  }
}

.cloud-shadow {
  filter: drop-shadow(0 4px 4px rgba($color: #000000, $alpha: 0.25));
}

.logo-shadow {
  box-shadow: 0 4px 4px rgba($color: #000000, $alpha: 0.25);
}
.card-shadow {
  box-shadow: 0 30px 80px rgba($color: #000000, $alpha: 0.1);
}

.header-shadow {
  header {
    box-shadow: 0 4px 4px rgba($color: #000000, $alpha: 0.25);
  }
}

.customDialogBackdrop {
  background-color: rgba(91, 179, 228, 0.5);
  -webkit-backdrop-filter: blur(12px);
  backdrop-filter: blur(12px);
}

.customDialogOverlay {
  .mat-mdc-dialog-container .mdc-dialog__surface {
    border-radius: 9px !important;
    box-shadow: 0 0 40px rgba($color: #5bb3e4, $alpha: 1);
  }
}

.loginOverlay {
  // mat-dialog-container {
  //   --mdc-dialog-container-color: transparent !important;
  // }
  .mat-mdc-dialog-content {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  mat-dialog-actions {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

.signUpOverlay {
  .mat-mdc-dialog-content {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  mat-dialog-actions {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

.scroll-margin {
  scroll-margin-top: 117px;
}

.custom-tooltip {
  white-space: pre-line;
  filter: drop-shadow(0 4px 50px rgba(0, 0, 0, 0.15));
  .mdc-tooltip__surface {
    background-color: white;
    color: var(--color-gray);
    font-size: 10px;
    padding: 10px 8px;
  }
  &::after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 50%;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 5px 10px 5px;
    border-color: transparent transparent white transparent;
    transform: translateX(-50%) rotate(180deg);
    z-index: -1;
  }
}

.custom-tooltip-header {
  white-space: pre-line;
  filter: drop-shadow(0 4px 50px rgba(0, 0, 0, 0.15));
  .mdc-tooltip__surface {
    background-color: white;
    color: var(--color-gray);
    font-size: 10px;
    padding: 10px 8px;
  }
}

.custom-autocomplete {
  mat-option {
    font-size: 12px;
    font-weight: 500;
    height: 40px;
    .mdc-list-item__primary-text {
      color: var(--color-gray-cool) !important;
    }
  }

  .mat-mdc-option:hover:not(.mdc-list-item--disabled),
  .mat-mdc-option:focus:not(.mdc-list-item--disabled),
  .mat-mdc-option.mat-mdc-option-active,
  .mat-mdc-option.mdc-list-item--selected:not(.mat-mdc-option-multiple):not(
      .mdc-list-item--disabled
    ) {
    background: rgba(#a1a5b7, 0.2);
  }
}
/* horizontally  scroll*/
.sign-in-step-one {
  overflow-y: auto !important;
  scrollbar-width: thin !important;
  &::-webkit-scrollbar-track {
    // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: transparent;
  }

  &::-webkit-scrollbar {
    width: 4px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--color-celestial-blue);
    border-radius: 4px;
  }
}

.scrollbar-thin {
  overflow-y: auto !important;
  scrollbar-width: thin !important;
  &::-webkit-scrollbar-track {
    // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: transparent;
  }

  &::-webkit-scrollbar {
    width: 4px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--color-celestial-blue);
    border-radius: 4px;
  }
}

.button-notification {
  padding: 6px 12px !important;
  border-radius: 6px !important;
  font-weight: 700;
  font-size: 12px;
  &:hover {
    opacity: 0.8;
  }
  &.premium {
    background: rgba(155, 89, 182, 0.3);
    color: #9b59b6;
  }
  &.reject {
    background: rgba(255, 87, 51, 0.3);
    color: #ff5733;
  }
  &.receiver {
    background: rgba(255, 195, 0, 0.3);
    color: #ffc300;
  }
  &.accept {
    background: rgba(72, 201, 176, 0.3);
    color: #48c9b0;
  }
}
/* Progress */
.mdc-linear-progress__bar-inner {
  border-color: #48c9b0 !important;
}

.custom-snackbar {
  margin-top: 85px !important;
  .mdc-snackbar__surface {
    background-color: var(--color-celestial-blue) !important;
  }

  .mat-mdc-button.mat-mdc-snack-bar-action:not(:disabled) {
    color: white !important;
    font-weight: bold;
    border: 2px solid white;
  }
}

.thin-scroll {
  scrollbar-width: thin !important;
  &::-webkit-scrollbar-track {
    // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: transparent;
  }

  &::-webkit-scrollbar {
    height: 4px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--color-celestial-blue);
    border-radius: 4px;
  }
}

::ng-deep {
  .spiner-white
    .mat-mdc-progress-spinner
    .mdc-circular-progress__indeterminate-circle-graphic {
    stroke: white !important;
  }
}
